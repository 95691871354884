<template>
  <div class="footer-box">
    <div class="main">
      <div>
        <div class="menu">
          <div class="head">易汇钱包</div>
          <router-link to="/service-terms">
            <div class="item-text">
              <span>服务条款</span>
            </div>
          </router-link>
          <router-link to="/privacy-policy">
            <div class="item-text">
              <span>隐私条款</span>
            </div>
          </router-link>
        </div>
        <div class="menu">
          <div class="head">公司</div>
          <router-link to="/aboutus">
            <div class="item-text">
              <span>关于我们</span>
            </div>
          </router-link>
          <router-link to="/joinus">
            <div class="item-text">
              <span>加入我们</span>
            </div>
          </router-link>
          <!-- <div class="item-text">
            <span>最新公告</span>
          </div> -->
        </div>
        <div class="menu">
          <div class="head">开放支付</div>
          <router-link to="/openpay">
            <div class="item-text">
              <span>解決方案</span>
            </div>
          </router-link>
          <router-link to="/openpay/scenario">
            <div class="item-text">
              <span>应用场景</span>
            </div>
          </router-link>
          
          <router-link to="/openpay/document">
            <div class="item-text">
            <span>开发文档</span>
          </div>
          </router-link>

          <router-link to="/openpay/assets">
            <div class="item-text">
            <span>资源下载</span>
          </div>
          </router-link>
        </div>
        <!-- <div class="menu">
          <div class="head">帮助中心</div>
          <div class="item-text">
            <span>新手指南</span>
          </div>
          <div class="item-text">
            <span>常见交易问题</span>
          </div>
        </div> -->
      </div>
      <!-- <div class="contact-box">
        <div class="time">社交分享</div>
        <div class="media">
          <img src="../images/10028.png" alt />
          <img src="../images/10029.png" alt />
          <img src="../images/10030.png" alt />
          <img src="../images/10031.png" alt />
        </div> 
      </div> -->
      <div class="online-custom-serve-fixed">
        <el-backtop :bottom="125">
          <div class="eo-backtop f-vcc">
            <a href="#">
              <div class="top"></div>
            </a>
          </div>
        </el-backtop>
        <div class="img-box">
          <a target="_blank" href="https://t.me/EHUIPAY">
          <img src="../images/homepage/tg_icon.png" />
        </a>
        </div>
        <!-- <div class="img-box">
          <img src="../images/homepage/service_icon.png" />
        </div> -->
        <!-- <router-link to="/download">
        <div class="img-box">
          <img src="../images/homepage/logo-22.png" />
        </div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>

<style lang="less" scoped>
.footer-box {
  width: 100%;
  height: 256px;
  padding-top: 36px;
  padding-bottom: 22px;
  background: #fff;
  border-top: 1px solid #a4b1ca;
}

.footer-box .main {
  margin: 0 auto;
  width: 1300px;
}

.footer-box .menu {
  width: 190px;
  float: left;
}

.footer-box .menu a {
  text-decoration: none;
}

.footer-box .menu .head {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 24px;
}

.footer-box .menu .item-text {
  cursor: pointer;
  font-size: 14px;
  color: #a4b1ca;
  line-height: 32px;
}

.footer-box .menu .item-text span:hover {
  color: #333;
}

.footer-box .contact-box {
  font-size: 14px;
  line-height: 19px;
}

.footer-box .contact-box .online {
  padding: 9px 18px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 7px;
  height: 40px;
  border-radius: 7px;
  border: 1px solid #fff;
}

// .footer-box .contact-box .online:hover {
//   color: #134eee;
//   background: #fff;
// }

.footer-box .contact-box .online .service-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.footer-box .contact-box .time {
  margin-bottom: 9px;
  line-height: 24px;
  font-size: 18px;
}

.footer-box .contact-box .time2 {
  margin-top: 9px;
  line-height: 32px;
  color: #888683;
}

.footer-box .contact-box .media {
  display: flex;
}

.footer-box .contact-box .media img {
  cursor: pointer;
  width: 28px;
  height: 28px;
  margin-right: 20px;
  opacity: 1;
}

.footer-box .contact-box .media img:hover {
  opacity: 0.9;
}

.footer-box .online-custom-serve-fixed {
  position: fixed;
  right: 32px;
  bottom: 40px;
  z-index: 9999999;
  display: flex;
  flex-flow: column;
}

.footer-box .online-custom-serve-fixed,
.footer-box .online-custom-serve-fixed .img-box {
  justify-content: center;
  align-items: center;
}

.footer-box .online-custom-serve-fixed .img-box {
    display: inline-flex;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    border-radius: 12.4vw;
    transition: all 0.5s;
    overflow: hidden;
    margin-bottom: 0.5vw;
    cursor: pointer;
}

.footer-box .online-custom-serve-fixed .img-box img {
  width: 50px;
}
.tg-box {
  width: 54px;
  height: 54px;
}

.footer-box .online-custom-serve-fixed p {
  margin-top: 10px;
  font-size: 14px;
  // color: #134eee;
  letter-spacing: 0;
  text-align: right;
  line-height: 14px;
  font-weight: 400;
}

.footer-box .backtop-custom {
  background-color: transparent;
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 30px;
}

.footer-box .eo-backtop {
  font-size: 14px;
  color: #142032;
  line-height: 19px;
}

.footer-box .eo-backtop .top {
  width: 48px;
  height: 48px;
  background: url(../images/homepage/to_top.png) no-repeat;
  background-size: cover;
}

</style>
