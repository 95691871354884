import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/download/download')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/help/help')
  },
  
  {
    path: '/openpay',
    name: 'Openpay',
    component: () => import('../views/openpay/openpay')
  }, 
  {
    path: '/openpay/scenario',
    name: 'Scenario',
    component: () => import('../views/openpay/scenario')
  },
  {
    path: '/openpay/document',
    name: 'Document',
    component: () => import('../views/openpay/document')
  },
  {
    path: '/openpay/assets',
    name: 'assets',
    component: () => import('../views/openpay/assets')
  },
  {
    path: '/openpay/assets',
    name: 'assets',
    component: () => import('../views/openpay/assets')
  },
  {
    path: '/service-terms',
    name: 'service-terms',
    component: () => import('../views/policy/service-terms')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/policy/privacy-policy')
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import('../views/aboutUs/aboutUs')
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: () => import('../views/aboutUs/joinus')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/mobile')
  },
  {
    path: '/mobile/download',
    name: 'Download',
    component: () => import('../views/mobile/download')
  },
  {
    path: '/mobile/openpay',
    name: 'Openpay',
    component: () => import('../views/mobile/openpay')
  },
  {
    path: '/mobile/openpay/scenario',
    name: 'Scenario',
    component: () => import('../views/mobile/scenario')
  },
  {
    path: '/mobile/openpay/document',
    name: 'Document',
    component: () => import('../views/mobile/document')
  },
  {
    path: '/mobile/openpay/assets',
    name: 'Assets',
    component: () => import('../views/mobile/assets')
  },
  {
    path: '/mobile/service-terms',
    name: 'service-terms',
    component: () => import('../views/mobile/policy/service-terms')
  },
  {
    path: '/mobile/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/mobile/policy/privacy-policy')
  },
  {
    path: '/mobile/joinus',
    name: 'Joinus',
    component: () => import('../views/mobile/joinus')
  },
  {
    path: '/mobile/about-us',
    name: 'Joinus',
    component: () => import('../views/mobile/aboutUs')
  },
  {
    path: '/mobile/help',
    name: 'Help',
    component: () => import('../views/mobile/help')
  },
  {
    path: '/mobile/tutorials/verified',
    name: 'Verified',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/tutorials/verified')
  },
  {
    path: '/mobile/tutorials/buymoney',
    name: 'Buymoney',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/tutorials/buymoney')
  },
  {
    path: '/mobile/tutorials/sellmoney',
    name: 'Sellmoney',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/tutorials/sellmoney')
  },
  {
    path: '/mobile/tutorials/bindbank',
    name: 'Bindbank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/tutorials/bindbank')
  },

  {
    path: '/tutorials',
    name: 'tutorials',
    component: () => import(/* webpackChunkName: "about" */ '../views/tutorials/index')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    // if(savedPosition){
    //   // eslint-disable-next-line no-undef
    //   return savedPosition;
    // } else {
      return { x: 0, y: 0}
    // }
  }
})
export default router
