<template>
  <div class="down-load-box">
    <div class="ol-bg f-bc">
      <div class="main f-bc">
        <div class="info f-xc">
          <img src="./images/homepage/logo-nav.png" class="logo" />
          <div class="text-b">
            <div class="tips">现在，请您下载吧</div>
          </div>
        </div>
        <div>
          <div class="down-way f-xc">
          <div
            id="qrcode"
            title="https://ehuipay.com/#/mobile/download"
          >
            <vue-qr
              :logo-src="logoSrc"
              :size="141"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="appSrc"
              colorDark="#fff"
              colorLight="#333"
            />
          </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
export default {
  name: "downloadQRcode",

  components: {
    VueQr,
  },
  data() {
    return {
      logoSrc: "",
      appSrc: "https://www.ehuipay.com/#/mobile/download",
      // appSrc: "https://xn1002.top/#/mobile/download"
    };
  },
};
</script>

<style scoped lang="less">
body,
html {
  margin: 0 auto;
}
.down-load-box {
  background: #333;
  border: 1px solid transparent;
  background-size: 100%;
  width: 100%;
  height: 196px;
}
.f-bc {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.f-xc {
  display: flex;
  align-items: center;
}
.main {
  margin: 0 auto;
  width: 1300px;
}

.down-load-box .ol-bg {
  width: 100%;
  height: 100%;
}
.qr-code {
  width: 152px;
  height: 152px;
  background: #fff;
  border-radius: 7px;
}
.eo-qr-code-ico {
  position: absolute;
  width: 40px;
  height: 40px;
}
.f-cc {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-load-box .info .logo {
  width: 100px;
  height: 100px;
}

.down-load-box .text-b {
  margin-left: 20px;
  padding-bottom: 10px;
  position: relative;
}

.down-load-box .text-b .des {
  width: 432px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  line-height: 36px;
  margin-bottom: 15px;
}

.down-load-box .text-b .tips {
  font-size: 36px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
}

.down-load-box .down-way .qr-code {
  // width: 120px;
  // height: 120px;
  border-radius: 7px;
  background-color: #fff;
}

.down-load-box .down-way .eo-qr-code-ico {
  position: absolute;
  width: 30px;
  height: 30px;
}
</style>
