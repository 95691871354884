<template>
  <div class="home-page cir-page">
    <div class="eo-page">
      <div class="header1">
        <div class="container">
          <div class="header-left">
            <img src="./images/homepage/logo.png" class="logo" />
          </div>
          <div class="header-right">
            <div>
              <el-dropdown>
                <span
                  style="position: relative; z-index: 999"
                  class="el-dropdown-link"
                >
                  关于易汇钱包
                  <span class="el-icon--right">
                    <img
                      src="./images/homepage/icon-arrow.png"
                      class="eo-munu-arrow"
                    />
                  </span>
                  <img
                    style="
                      width: 30px;
                      position: absolute;
                      bottom: -15px;
                      right: 53px;
                    "
                    src="./images/homepage/line2.jpg"
                  />
                </span>
                <template>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <div
                        @click="toproduct"
                        style="
                          z-index: 999;
                          position: relative;
                          color: #333;
                          font-size: 17px;
                          font-weight: bold;
                        "
                      >
                        <span style="padding-right: 150px">关于EH币</span
                        ><img
                          style="width: 20px; height: 12px"
                          src="./images/homepage/arrow-right.png"
                        />
                      </div>
                      <div style="margin-top: -10px; color: #a4b1ca">
                        世界通用数字资产
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <div
                        @click="toproductUse"
                        style="
                          z-index: 999;
                          color: #333;
                          font-size: 17px;
                          font-weight: bold;
                        "
                      >
                        <span style="padding-right: 120px">使用易汇钱袋</span
                        ><img
                          style="width: 20px; height: 12px"
                          src="./images/homepage/arrow-right.png"
                        />
                      </div>
                      <div style="margin-top: -10px; color: #a4b1ca">
                        自由兑换、自由支付EH币
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div>
              <router-link to="/openpay">
                <div style="position: relative">
                  <span>开放支付</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-box">
        <!-- <Header /> -->
        <div class="text-area main f-vc">
          <img src="./images/homepage/banner_line.png" alt="" class="fill00" />
          <!-- <img src="./images/homepage/phone.png" alt="" class="fill001"> -->

          <div class="header-content">
            <div>
              <div class="header-download">
                <div style="display: flex; flex-direction: column">
                  <p class="header-left-title">易汇钱包</p>

                  <div class="header-left-title-desc">
                    1分钟飙速加值，畅行游戏
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      padding-top: 20px;
                      align-items: center;
                    "
                  >
                    <div class="header-download-left">
                      <div class="header-download-left-line1">
                        ios下载地址：
                      </div>
                      <div class="header-download-left-line2">
                        <a>
                          <button
                            class="header-download-left-line2-ios"
                            @click="getIosUrl"
                          >
                            <img src="./images/homepage/ios1.png" />
                            <span>iOS下载</span>
                          </button>
                        </a>
                      </div>
                      <div class="header-download-left-line1">
                        Android下载地址：
                      </div>
                      <div class="header-download-left-line2">
                        <a>
                          <button
                            class="header-download-left-line2-android"
                            @click="getAndroidUrl"
                          >
                            <img src="./images/homepage/android1.png" />
                            <span>Android下载</span>
                          </button>
                        </a>
                      </div>
                    </div>

                    <div class="header-barcode">
                      <div class="qr-size">
                        <vue-qr
                          :margin="10"
                          :auto-color="true"
                          :dot-scale="1"
                          :text="appSrc"
                          colorDark="#333000"
                          colorLight="#ffffff"
                        />
                        <div style="padding-top: 15px">扫码下载包</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="z-index: 999">
              <img
                src="./images/homepage/phone.png"
                style="margin-left: 159px"
              />
            </div>
          </div>
          <div class="header-footer">
            <button class="button" @click="gotoRecharge">实名教程</button>
            <button class="button" @click="gotoPickup">绑卡教程</button>
            <button class="button" @click="gotoBuymoney">买币教程</button>
            <button class="button" @click="gotoSellmoney">卖币教程</button>
          </div>
          <div class="f-vc">
            <div class="t1">助您安全、自由支付</div>
            <div class="t2">
              EH币 - 世界通用数字资产，恒定人民币汇率，100%互兑
            </div>
            <div class="line"></div>
            <div class="t3">无需远行即可畅玩全球</div>
          </div>
        </div>
      </div>
      <div class="des-info-box f-bc wow">
        <div class="main f-bc">
          <div class="card f-vcc">
            <img src="./images/homepage/merit_icon_1.png" alt="" class="icon" />
            <div class="info">简单</div>
            <div class="des">遵从“极致简单”的产品设计理念</div>
            <div class="des">小白用户也能快速入手</div>
          </div>
          <div class="card f-vcc">
            <img src="./images/homepage/merit_icon_2.png" alt="" class="icon" />
            <div class="info">安全</div>
            <div class="des">多重保护技术保障您的数字资产安全</div>
            <div class="des">放心管理您的钱包</div>
          </div>
          <div class="card f-vcc">
            <img src="./images/homepage/merit_icon_3.png" alt="" class="icon" />
            <div class="info">省心</div>
            <div class="des">严格的风控和24小时人工服务</div>
            <div class="des">为您打造无忧OTC交易环境</div>
          </div>
          <div class="card f-vcc">
            <img src="./images/homepage/merit_icon_4.png" alt="" class="icon" />
            <div class="info">极速</div>
            <div class="des">全新模式与强大生态伙伴</div>
            <div class="des">为您带来未曾有过的交易速度</div>
          </div>
        </div>
      </div>
      <div id="toproduct-about" class="platform-box wow">
        <div id="platform-target"></div>
        <div class="title-box main">
          <img src="./images/homepage/logo-22.png" class="logo" />
          <div class="name">关于EH币</div>
          <div class="desc">
            使用易汇钱包管理、世界通用的、恒定人民币汇率数字资产
          </div>
          <div class="line"></div>
        </div>
        <div class="content-box-wrap main">
          <div class="content-box">
            <img src="./images/homepage/about_pic01.png" alt="" class="img2" />
            <div class="text-b">
              <div class="t1">恒定1:1人民币汇率</div>
              <div class="t2">
                与其他数字货币不同，EH币承诺永久无涨跌、也不加入任何第三方交易所。无论何时，都可以1:1在人民币和易汇钱包之间自由兑换
              </div>
              <div class="t2"></div>
            </div>
          </div>
          <div class="content-box">
            <img src="./images/homepage/about_pic02.png" alt="" class="img2" />
            <div class="text-b">
              <div class="t1">稳定、安全、100%兑换</div>
              <div class="t2">
                全球数十万人使用，秒级交易匹配。易汇钱包官方联合全球多个跨国集团共同保底，无需担心持有的易汇钱包无法兑换
              </div>
            </div>
          </div>
          <div class="content-box">
            <img src="./images/homepage/about_pic03.png" alt="" class="img2" />
            <div class="text-b">
              <div class="t1">全球通用、自由度</div>
              <div class="t2">
                EH币使用区块链技术具有高度匿名的特性，交易无迹可寻。世界范围内已有上千家大型商户支持易汇钱包，全球支付无障碍
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="toproduct-use" class="pay-box wow">
        <div class="title-box-wrap main f-bc">
          <div class="title-box">
            <div class="name">使用易汇钱包</div>
            <div class="desc">自由兑换、自由支付EH币</div>
            <div class="line"></div>
            <div class="desc2">
              <div class="title">轻松支付</div>
              <p>
                使用易汇钱包，您可以轻松购买EH币，并存入您的易汇钱包账户。您可以随时使用易汇钱包支付给受支持的商家。
              </p>
              <p>
                我们在全世界已有1000+合作商家，现在就使用易汇钱包畅享缤纷服务吧
              </p>
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic01.png" alt="" />
            <span>STEP1：</span>
            <p>电子支付购买EH币</p>
          </div>
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic02.png" alt="" />
            <span>STEP2：</span>
            <p>购买 EH 币存入易汇钱包账户</p>
          </div>
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic03.png" alt="" />
            <span>STEP3：</span>
            <p>随时使用EH币轻松支付给商家</p>
          </div>
          <div class="arrow-con arrow1">
            <span>购买</span>
            <img src="./images/homepage/10017.png" />
          </div>
          <div class="arrow-con arrow2">
            <span>支付</span>
            <img src="./images/homepage/10017.png" />
          </div>
        </div>
        <div class="title-box-wrap main f-bc" style="margin-top: -97px">
          <div class="title-box">
            <div class="desc2">
              <div class="title">随时兑现</div>
              <p>
                您从商家提取EH币至您的易汇钱包账户后，无论何时，您都可以打开您的易汇钱包，将EH币出售，并获得人民币。
              </p>
              <p>
                我们提供极速、安全的交易渠道。不仅保障您能快速获取人民币，且非常重视您的银行账户安全，严控每一笔资金来源
              </p>
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic04.png" alt="" />
            <span>STEP4：</span>
            <p>从商家提取易汇钱包至易汇钱包</p>
          </div>
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic05.png" alt="" />
            <span>STEP5：</span>
            <p>从易汇钱包卖出EH币</p>
          </div>
          <div class="step-item">
            <img src="./images/homepage/use_ehpay_pic06.png" alt="" />
            <span>STEP6：</span>
            <p>获得人民币</p>
          </div>
          <div class="arrow-con arrow1">
            <span>提取</span>
            <img src="./images/homepage/10017.png" />
          </div>
          <div class="arrow-con arrow2">
            <span>销售</span>
            <img src="./images/homepage/10017.png" />
          </div>
        </div>
      </div>
      <div class="pay-box wow">
        <div class="title-box-wrap main f-bc">
          <div class="title-box">
            <div class="name">开放支付</div>
            <div class="desc">
              如果您是商家，欢迎您加入EH币生态，简单几步立即拥有数十万EH币用户
            </div>
            <div class="line"></div>
          </div>
          <div class="platform-list">
            支持平台
            <img
              src="./images/homepage/pt_android.png"
              alt=""
              class="platform-icon"
            />
            <img
              src="./images/homepage/pt_apple.png"
              alt=""
              class="platform-icon"
            />
            <img
              src="./images/homepage/pt_h5.png"
              alt=""
              class="platform-icon"
            />
            <img
              src="./images/homepage/pt_ie.png"
              alt=""
              class="platform-icon"
            />
          </div>
        </div>
        <div class="content-box">
          <div class="cards f-cc main">
            <div class="card card-btn">
              <div class="cover f-vcc">
                <div class="img-wrap">
                  <img src="./images/homepage/current_icon_1.png" />
                </div>
                <div class="img-wrap active-img">
                  <img src="./images/homepage/1-2.png" />
                </div>
                <div class="text">解决方案</div>
              </div>
            </div>
            <div class="card card-btn">
              <div class="cover f-vcc">
                <div class="img-wrap">
                  <img src="./images/homepage/current_icon_2.png" />
                </div>
                <div class="img-wrap active-img">
                  <img src="./images/homepage/2-2.png" />
                </div>
                <div class="text">应用场景</div>
              </div>
            </div>
            <div class="card card-btn">
              <div class="cover f-vcc">
                <div class="img-wrap">
                  <img src="./images/homepage/current_icon_3.png" />
                </div>
                <div class="img-wrap active-img">
                  <img src="./images/homepage/3-2.png" />
                </div>
                <div class="text">开发文档</div>
              </div>
            </div>
            <div class="card card-btn">
              <div class="cover f-vcc">
                <div class="img-wrap">
                  <img src="./images/homepage/current_icon_4.png" />
                </div>
                <div class="img-wrap active-img">
                  <img src="./images/homepage/4-2.png" />
                </div>
                <div class="text">社区</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DownloadQRcode />
    <Footer />
    <img src="./images/homepage/10034.png" alt="" class="circle_01" />
    <img
      src="./images/homepage/circle_right.png"
      alt=""
      class="circle_right_down"
    />
  </div>
</template>

<script>
// import Header from "./head/index.vue";
import VueQr from "vue-qr";
import axios from "axios";
import Footer from "./footer/index.vue";
import DownloadQRcode from "./downloadQRcode.vue";

export default {
  name: "Home",
  components: {
    // Header,
    VueQr,
    Footer,
    DownloadQRcode,
  },
  created() {
    if (!this.$pc) {
      this.$router.push({
        path: "/mobile",
      });
    }
    // this.getIos();
  },
  data() {
    return {
      logoSrc: "",
      appSrc: "https://www.ehuipay.com/#/mobile/download",
      // appSrc: "https://xn1002.top/#/mobile/download",
      isIos: false,
      urlIos: "",
      urlAndroid: "",
    };
  },
  methods: {
    getIos() {
      var u = navigator.userAgent;
      var isios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isandroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
      if (isios) {
        this.isIos = true;
        this.getIosUrl();
      } else if (isandroid) {
        this.isIos = false;
        this.getAndroidUrl();
      }
    },
    getIosUrl() {
      axios({
        method: "get",
        url: "https://app.ehuipay.com/api/version",
        params: { type: "ios", appversion: "1.0.0" },
      }).then((res) => {
        this.urlIos = res.data.data.downurl;
        window.open(this.urlIos);
      });
    },
    getAndroidUrl() {
      axios({
        method: "get",
        url: "https://app.ehuipay.com/api/version",
        params: { type: "android", appversion: "1.0.0" },
      }).then((res) => {
        this.urlAndroid = res.data.data.downurl;
        window.open(this.urlAndroid);
      });
    },
    gotoSellmoney() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "sellmoney",
        },
      });
    },
    gotoPickup() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "pickup",
        },
      });
    },
    gotoBuymoney() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "buymoney",
        },
      });
    },
    gotoRecharge() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "recharge",
        },
      });
    },
    toDownload() {
      this.$router.push({ path: "/download" });
    },
    toproduct() {
      var target = document.querySelector("#toproduct-about");
      target.scrollIntoView(true);
    },
    toproductUse() {
      var target = document.querySelector("#toproduct-use");
      target.scrollIntoView(true);
    },
  },
};
</script>
<style scoped lang="less">
.header-content {
  position: relative;
  top: 3.125rem;
  display: flex;
  // flex-direction: row;
  // justify-content: center;
  .header-left-title {
    text-align: left;
    height: 3.6875rem;
    font-size: 4.1875rem;
    font-family: MicrosoftHaHei;
    font-weight: 400;
    color: #333;
    line-height: 4.5rem;
    margin-bottom: 1.25rem;
  }
  .header-left-title-desc {
    text-align: left;
    // width: 21.0625rem;
    height: 1.5625rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #999;
    line-height: 2.25rem;
  }
}

.header-download {
  margin-top: 8.9375rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  // width: 31.25rem;
  .header-download-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4.5rem 4.5rem 4.5rem 0;
    .header-download-left-line1 {
      height: 2.5rem;
      line-height: 2.5rem;
      background-color: #f1ece4;
      text-align: left;
      font-size: 16pt;
      padding-left: 0.5625rem;
      // padding-top: 0.5rem;
      font-size: 12pt;
      font-family: MicrosoftHaHei;
      color: #999;
    }
    .header-download-left-line2 {
      display: flex;
      justify-content: space-around;
      margin-top: 0.875rem;
      margin-bottom: 1.25rem;
      flex-wrap: wrap;
      .header-download-left-line2-ios {
        color: #4f4736;
        text-align: center;
        text-decoration: none;
        font-size: 14pt;
        cursor: pointer;
        height: 2.5rem;
        background: #ff9900;
        border-radius: 2.9375rem;
        width: 18rem;
        border: none;
        span {
          font-size: 16px;
          font-family: MicrosoftHaHei;
          font-weight: 400;
          color: #4f4736;
          line-height: 0.875rem;
          position: relative;
          top: -0.13rem;
          left: 0.15rem;
        }
        img {
          height: 1.5rem;
          position: relative;
          right: 0.15rem;
        }
      }
      .header-download-left-line2-android {
        color: #4f4736;
        text-align: center;
        cursor: pointer;
        height: 2.5rem;
        background: #ff9900;
        border-radius: 2.9375rem;
        width: 18rem;
        border: none;
        img {
          height: 1.5rem;
          position: relative;
          right: 0.15rem;
        }
        span {
          font-size: 16px;
          font-family: MicrosoftHaHei;
          color: #4f4736;
          position: relative;
          top: -0.23rem;
          left: 0.15rem;
        }
      }
    }
    .header-download-left-line3 {
      display: flex;
      justify-content: flex-start;
    }
  }
  .header-barcode {
    margin-top: -40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    .qr-size {
      // width: 280px;
      // height: 280px;
    }
    img {
      width: 220px;
      height: 220px;
    }
    span {
      // width: 6.3125rem;
      height: 1rem;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      font-weight: 600;
      color: #333;
      // line-height: 2.25rem;
    }
  }
}

.header-footer {
  position: relative;
  top: 50px;
  // bottom: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #ff9900;
    border: 2px solid #ff9900 !important;
    // padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin: 2px 2px;
    cursor: pointer;
    width: 224px;
    height: 56px;
    background: #fff;
    box-shadow: 0px 13px 36px 2px rgba(233, 144, 64, 0.35);
    border-radius: 41px;

    font-family: MicrosoftHaHei;
    font-weight: 600;
  }
}
.header1 {
  background: #ff9900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1999;
  transition: all 0.3s;
  .container {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 0px;
    color: #fff;
    margin: 0 auto;
    width: 1300px;
    .active {
      color: red;
    }

    /deep/.el-dropdown {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    .header-left {
      .logo {
        width: 128px;
        height: 52px;
        margin-right: 80px;
      }
    }
    .header-right {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      width: 21%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: #fff;
        text-decoration: none;
      }
      .el-icon--right {
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  background: #f0f5f9;
}
.cir-page:before {
  position: absolute;
  display: inline-block;
  content: "";
  width: 244px;
  height: 486px;
  left: 0;
  top: 1100px;
  background-image: url(./images/homepage/circle_left.png);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.home-page .banner-box .text-area .fill00 {
  height: 283px;
  position: absolute;
  top: 20px;
  /* right: 215px; */
  z-index: 0;
  left: -91px;
}
.home-page .banner-box .text-area .fill001 {
  /* width: 882px; */
  height: 483px;
  position: absolute;
  right: -83px;
  top: 60px;
  z-index: 0;
}

.cir-page:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 323px;
  height: 438px;
  top: 0;
  right: 0;
  background-image: url(./images/homepage/circle_right.png);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.f-v {
  display: flex;
  flex-direction: column;
}
.f-vc {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.f-cc {
  flex-direction: row;
}

.f-cc,
.f-vcc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-vcc {
  flex-direction: column;
}

.f-bc {
  flex-direction: row;
  justify-content: space-between;
}

.f-bc,
.f-xc {
  align-items: center;
}

.f-bc,
.f-vb,
.f-xc {
  display: flex;
}

.container,
.main {
  margin: 0 auto;
  width: 1300px;
}
.eo-top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1999;
  transition: all 0.3s;
  background: #ff6600;
}
.eo-page {
  min-height: calc(100vh - 452px);
}

a,
a:active,
a:focus,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}

*,
:after,
:before,
html {
  box-sizing: border-box;
}

*,
:after,
:before {
  margin: 0;
}

.home-page {
  padding-top: 80px;
  z-index: 1;
}

.home-page,
.home-page #package {
  position: relative;
}

.home-page #package #package-target {
  position: absolute;
  top: -30px;
}

.home-page #platform {
  position: relative;
}

.home-page #platform #platform-target {
  position: absolute;
  top: -120px;
}

.home-page .btn,
.home-page .btn .span {
  text-align: center;
}

.home-page .btn:hover {
  opacity: 0.9;
}

.home-page .btn img {
  width: 14px;
  height: 15px;
  margin-right: 6px;
}

.home-page .banner-box {
  position: relative;
  text-align: center;
  line-height: 0;
}

.home-page .banner-box .eo-top-nav {
  background: #ff9900;
}

.home-page .banner-box .eo-top-nav > div {
  width: 100%;
  padding: 0 30px;
}

.home-page .banner-box .img {
  width: 100%;
}

.home-page .banner-box .text-area {
  left: 0;
  text-align: left;
  /* color: #134eee; */
  margin-bottom: 120px;
  position: relative;
  /* background: url(./imges/banner_line@3x.0bdb6de.png) no-repeat -83px 66px; */
  background-size: 882px 283px;
  background-image: "./imges/banner_line@3x.0bdb6de.png";
}

.home-page .banner-box .text-area .t1 {
  margin-top: 200px;
  font-size: 56px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  line-height: 56px;
}

.home-page .banner-box .text-area .t2 {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #142032;
  line-height: 18px;
  margin: 20px 0;
}

.home-page .banner-box .text-area .t3 {
  margin-top: 20px;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 40px;
}

.home-page .banner-box .text-area .line {
  width: 70px;
  height: 3px;
  background: #ff9f27;
}

.home-page .banner-box .text-area .download-btn {
  margin-top: 60px;
  width: 220px;
  height: 50px;
}

.home-page .banner-box .text-area .download-btn span {
  z-index: 999;
  cursor: pointer;
  width: 220px;
  height: 50px;
  /* color: #fff; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  background: #ff9900;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-area .download-btn span a {
  color: #333;
}

.home-page .banner-box .text-area .download-btn span:hover {
  opacity: 0.9;
}

.home-page .banner-box .text-area .download-btn .icon {
  width: 17px;
  height: 14px;
  margin-right: 3px;
}

.home-page .des-info-box {
  width: 100%;
  height: 385px;
}

.home-page .des-info-box .main {
  height: 280px;
  justify-content: space-between;
  position: relative;
}

.home-page .des-info-box .main:before {
  opacity: 0.2;
}

.home-page .des-info-box .f-vcc {
  border-radius: 7px;
  border: 1px solid #a4b1ca;
  z-index: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
}

.home-page .des-info-box .f-vcc:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="1" /></filter></svg>#filter');
  filter: blur(1px);
  opacity: 0.1;
  z-index: -1;
}

/* .home-page .des-info-box .f-vcc:before {
  background: linear-gradient(1turn, #a4b1ca, #e6ecf9);
}

.home-page .des-info-box .f-vcc:before {
  background: linear-gradient(#7a7369, #2d2c2b);
} */

.home-page .des-info-box .f-vcc:first-child .icon {
  width: 44px;
  height: 64px;
}

.home-page .des-info-box .f-vcc:nth-child(2) .icon {
  width: 55px;
  height: 64px;
}

.home-page .des-info-box .f-vcc:nth-child(3) .icon {
  width: 62px;
  height: 65px;
}

.home-page .des-info-box .f-vcc:nth-child(4) .icon {
  width: 54px;
  height: 64px;
}

.home-page .des-info-box .card {
  height: 280px;
  width: 298px;
}

.home-page .des-info-box .card .info {
  margin-top: 32px;
  margin-bottom: 17px;
  font-size: 24px;
  /* color: #134eee; */
  font-family: MicrosoftYaHei;
  line-height: 24px;
  font-weight: bold;
}

.home-page .des-info-box .card .des {
  width: 245px;
  font-size: 14px;
  color: #142032;
  line-height: 20px;
  text-align: center;
}

.pay-box .step {
  width: 1300px;
  justify-content: space-between;
  margin: -55px auto 55px;
  position: relative;
}

.pay-box .step,
.pay-box .step .arrow-con {
  display: flex;
}

.pay-box .step .arrow-con {
  position: absolute;
  left: 350px;
  top: 130px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pay-box .step .arrow-con span {
  /* color: #134eee; */
  font-size: 18px;
}

.pay-box .step .arrow-con img {
  margin-top: 10px;
  width: 113px;
}

.pay-box .step .arrow2 {
  left: 840px;
}

.pay-box .step .step-item {
  width: 320px;
  height: 324px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  border: 1px solid #a4b1ca;
  border-radius: 6px;
}

.pay-box .step .step-item span {
  color: #a4b1ca;
  padding-left: 30px;
  margin-bottom: 10px;
}

.pay-box .step .step-item p {
  padding-left: 30px;
  color: #142032;
  font-weight: 600;
}

.pay-box .step .step-item img {
  width: 220px;
  height: 170px;
  margin: 38px auto 20px;
}

.pay-box .content-box {
  margin-top: 40px;
}

.pay-box .content-box .cards {
  display: flex;
  margin: 0 auto 240px;
  justify-content: space-between;
}

.pay-box .content-box .card {
  margin-right: 60px;
  position: relative;
  width: 280px;
  height: 140px;
  border-radius: 7px;
  overflow: inherit;
}

.pay-box .content-box .card:hover {
  overflow: inherit;
  /* background: blue; */
}
.pay-box .content-box .card:hover .active-img {
  display: block;
}

.pay-box .content-box .card:last-child {
  margin-right: 0;
}

.pay-box .content-box .card img {
  width: 280px;
  height: 320px;
}

.pay-box .content-box .card .cover {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  background: #ffbd5d;
  overflow: inherit;
}

.pay-box .content-box .card .cover .img-wrap {
  padding: 29px;
  border-radius: 50px;
  border: 1px solid #f3f3f3;
  top: -50px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  background: #fff;
  opacity: 1;
  position: absolute;
  width: 100px;
  height: 100px;
}

.pay-box .content-box .card .cover .img-wrap img {
  width: 40px;
  height: 40px;
  box-sizing: content-box;
}

.pay-box .content-box .card .cover .text {
  margin-top: 30px;
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  /* color: #142032; */
  line-height: 18px;
}

.pay-box .content-box .card-btn {
  transition: all 0.3s;
  cursor: pointer;
}

.pay-box .content-box .card-btn:hover {
  transform: translateY(-10px);
  transition: all 0.3s;
  z-index: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  overflow: inherit;
}

.pay-box .content-box .card-btn:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="1" /></filter></svg>#filter');
  filter: blur(1px);
  opacity: 0.1;
  z-index: -1;
}

.pay-box .content-box .card-btn:hover:before {
  background: linear-gradient(1turn, #a4b1ca, #e6ecf9);
}

.pay-box .content-box .card-btn:hover:before {
  background: linear-gradient(#7a7369, #2d2c2b);
}

.platform-box .title-box {
  padding-left: 130px;
}

.platform-box .content-box-wrap {
  display: flex;
  justify-content: space-between;
}

.platform-box .content-box {
  margin-bottom: 60px;
  transition: all 0.3s;
  width: 400px;
  height: 492px;
  border-radius: 7px;
  border: 1px solid #a4b1ca;
  text-align: center;
}

.platform-box .content-box:hover {
  transform: translateY(-10px);
  transition: all 0.3s;
  z-index: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
}

.platform-box .content-box:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="1" /></filter></svg>#filter');
  filter: blur(1px);
  opacity: 0.1;
  z-index: -1;
}

.platform-box .content-box:hover:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#a4b1ca),
    to(#e6ecf9)
  );
  background: linear-gradient(1turn, #a4b1ca, #e6ecf9);
}

.platform-box .content-box:hover:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#7a7369),
    to(#2d2c2b)
  );
  background: linear-gradient(#7a7369, #2d2c2b);
}

.platform-box .content-box:first-child .img2,
.platform-box .content-box:nth-child(2) .img2,
.platform-box .content-box:nth-child(3) .img2 {
  width: 290px;
  height: 220px;
  margin: 56px auto 52px;
}

.platform-box .content-box .gap {
  width: 100px;
}

.platform-box .content-box .img {
  width: 464px;
  height: 464px;
}

.platform-box .content-box .img2 {
  width: 330px;
  height: 371px;
}

.platform-box .content-box .img3 {
  width: 438px;
  height: 328px;
}

.platform-box .content-box .text-b {
  text-align: left;
}

.platform-box .content-box .text-b .btn img {
  width: 12px;
  height: 14px;
  margin-right: 6px;
}

.platform-box .content-box .text-b .t1 {
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  padding-left: 33px;
  font-weight: bold;
}

.platform-box .content-box .text-b .t2 {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #142032;
  line-height: 28px;
  margin: 27px auto;
  padding: 0 33px;
}

.title-box-wrap .platform-list {
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  display: flex;
}

.title-box-wrap .platform-list .platform-icon {
  margin-left: 30px;
  height: 39px;
  width: auto;
}

.title-box {
  position: relative;
  margin-top: 50px;
  margin-bottom: 100px;
  /* color: #134eee; */
}

.title-box .logo {
  width: 100px;
  height: 100px;
  left: 0;
  position: absolute;
}

.title-box .name {
  font-size: 36px;
  font-weight: 600;
  /* color: #134eee; */
  line-height: 36px;
}

.title-box .desc {
  margin-top: 15px;
  font-size: 16px;
  width: 800px;
  font-family: MicrosoftYaHei;
  /* color: #a4b1ca; */
}

.title-box .desc2 .title {
  margin: 42px 0 18px;
  font-size: 30px;
  font-weight: 600;
}

.title-box .desc2 p {
  font-size: 16px;
  color: #142032;
  line-height: 24px;
}

.title-box .line {
  margin-top: 30px;
  width: 40px;
  height: 3px;
  background: #ff9900;
  border-radius: 3px;
}

.circle_01,
.circle_02,
.circle_04,
.circle_05_left,
.circle_05_right,
.circle_left,
.circle_left_down,
.circle_right,
.circle_right_down {
  position: absolute;
  z-index: -1;
}

.circle_right {
  height: 438px;
  top: 0;
  right: 0;
}

.circle_01 {
  height: 702px;
  top: 320px;
  right: 0;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.circle_right_down {
  height: 473px;
  bottom: 620px;
  width: 353px;
  right: 0;
}
</style>
